$(document).ready(function() {
    'use strict';

    /***********************************************
     *  VARIABLES
     **********************************************/
    WEB.VARS = $.extend(WEB.VARS, {
    });


    /***********************************************
     *  FUNCTIONS - visible only in this file
     **********************************************/
    function setCookie(cname,value,exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var cvalue = escape(value) + ((exdays === null) ? '' : '; expires=' + exdate.toUTCString()) + '; path=/';
        document.cookie = cname + "=" + cvalue;
    }

    function getCookie(cname) {
        var cvalue = document.cookie;
        var cstart = cvalue.indexOf(" " + cname + "=");

        if (cstart === -1) {
            cstart = cvalue.indexOf(cname + "=");
        }
        if (cstart === -1) {
            cvalue = null;
        }
        else {
            cstart = cvalue.indexOf("=", cstart) + 1;
            var cend = cvalue.indexOf(";", cstart);
            if (cend === -1) {
             cend = cvalue.length;
            }
            cvalue = unescape(cvalue.substring(cstart,cend));
        }
        return cvalue;
    }

    function cookies() {
		var $elem = $('.js-cookies'),
            $trigger = $elem.find('.js-close-cookies'),
			$openPolicy = $elem.find('.open-policy');

		var cookie = getCookie('taxi-new-user');

		if(cookie === null) {
			$elem.show();
		}

		$openPolicy.on('click', function(){
			setCookie("taxi-new-user",1,500);
		});

		$trigger.on('click', function(e) {
			e.preventDefault();
			setCookie("taxi-new-user",1,500);
			$elem.fadeOut('slow');
		});
	}

    function mouseParallax() {
        var options = {
            enable: true,
            effectWeight: 1.2,
            enableSmoothing: true,
            smoothingMultiplier: 1,
            activeOnlyInside: true,
            outerBuffer: 1.008,
            elementDepth: 100,
            weights: [0.0000050, 0.0000400, 0.0000064, 0.0000100, 0.0000100],
        };

        $('header').imagesLoaded(function() {
            $('.js-mouse-parallax').logosDistort(options);
        });
    }

    function scrollPage() {
        var scrollPos  = $(document).scrollTop();

        if( scrollPos > 20 ) {
            WEB.VARS.BODY.addClass('scrolled');
        } else {
            WEB.VARS.BODY.removeClass('scrolled');
        }
    }

    function viewportChecker() {
        var offset = '0%';

        if(WEB.VARS.IS_DESKTOP) {
            offset = '3%';
        }

        $('.js-box-animation').viewportChecker({
            classToAdd: 'is-visible',
            offset: offset
        });
    }

    function initMap() {
        var mapDiv = document.getElementById('map');
        var myLatLng = new google.maps.LatLng(50.1236382, 18.9126781);
        var travelStyles = [{"featureType":"administrative","stylers":[{"visibility":"on"}]},{"featureType":"poi","stylers":[{"visibility":"simplified"}]},{"featureType":"road","stylers":[{"visibility":"simplified"}]},{"featureType":"water","stylers":[{"visibility":"simplified"}]},{"featureType":"transit","stylers":[{"visibility":"simplified"}]},{"featureType":"landscape","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"visibility":"off"}]},{"featureType":"road.local","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"water","stylers":[{"color":"#84afa3"},{"lightness":52}]},{"stylers":[{"saturation":-77}]},{"featureType":"road"}];
        var zoom = 10;
        var mapOptions = {
            center: myLatLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: zoom,
            mapTypeControl: false,
            streetViewControl: false,
            scaleControl: false,
            scrollwheel: false,
            draggable: false,
            styles: travelStyles
        };

        if(WEB.VARS.IS_DESKTOP) {
            mapOptions.draggable = true;
        }

        var map = new google.maps.Map(mapDiv, mapOptions);

        var marker = new google.maps.Marker({
			position: myLatLng,
			map: map,
            title: 'Taxi London - wynajem limuzyny weselnej',
            icon: 'dist/images/map-marker.png'
		});

        marker.addListener('click', function() {
            zoom += 2;
            map.setZoom(zoom);
            map.setCenter(marker.getPosition());
        });

		google.maps.event.addDomListener(window, 'resize', function() {
			map.setCenter(myLatLng);
		});

        marker.setMap(map);
     }

    function showWebsite() {
        WEB.VARS.BODY.imagesLoaded(function() {
            WEB.VARS.BODY.addClass('is-visible');
        });
    }

    function smoothScroll() {
        $('.js-smooth-scroll').on('click', function() {
            WEB.VARS.BODY.animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top
            }, 750);
        });
    }

    function hideNavigation() {
        $('.js-navigation').on('click', function() {
            $('.js-box-animation').addClass('is-visible');
            $('#main-navigation').prop('checked', false);
            WEB.VARS.BODY.toggleClass('navigation--is-open');
        });

        $('#main-navigation').on('change', function() {
            WEB.VARS.BODY.toggleClass('navigation--is-open');
        });
    }

    function contactForm() {
        var check_1 = (Math.floor(Math.random() * 9) + 1).toString(),
            check_2 = (Math.floor(Math.random() * 9) + 1).toString(),
            check = check_1 + check_2;

        $('#contactform span.form_check_1').text(check_1);
        $('#contactform span.form_check_2').text(check_2);
        $('#contactform input[name="contact_check_data"]').val(check);

        var sending = false;
        $('#contactform').on('submit', function(e) {
            var $form = $(this), $submit = $('input[type="submit"]', $form);

            e.preventDefault();

            if (sending) {
                return false;
            }

            $('.form-error', $form).remove();

            $('input, textarea', $form).prop('readonly', true);
            $submit.val('Wysyłam…');
            sending = true;

            $.post($form.attr('action'), $form.serialize(), function(data) {
                if (data === 'ok') {
                    $form.slideUp('fast', function() {
                        $form.after('<div class="form--success">Wiadomość została wysłana! Skontaktujemy się z Tobą najszybciej jak tylko to będzie możliwe.</div><div class="form__btn"><button class="btn btn--light btn--big" data-remodal-action="cancel">Zamknij</button></div>');
                    });

                    return true;
                }

                $form.prepend('<div class="form--error">Wystąpił błąd podczas wysyłania formularza. Upewnij się, że wypełniłeś wszystkie pola i poprawnie rozwiązałeś działanie.</div>');
                $('input, textarea', $form).prop('readonly', false);
                $submit.val('Wyślij wiadomość');
                sending = false;

                return false;
            }, 'text');
        });
    }

    function initHome() {
        mouseParallax();
        scrollPage();
        viewportChecker();
        showWebsite();
        initMap();
        smoothScroll();
        hideNavigation();
        contactForm();
        cookies();

        lightbox.option({
          'resizeDuration': 200,
          'disableScrolling': true,
          'showImageNumberLabel': false,
        });
    }


    /***********************************************
     *  METHODS - visible in other JS files
     **********************************************/
    WEB.METHODS = $.extend(WEB.METHODS, {
    });


    /***********************************************
     *  VIEWPORT - If viewport has been resized
     **********************************************/
    WEB.VIEWPORT = $.extend(WEB.VIEWPORT, {
    });


    /***********************************************
     *  SCROLL - If page is scrolled
     **********************************************/
     WEB.SCROLL = $.extend(WEB.SCROLL, {
        scrollPage: scrollPage
     });


    /***********************************************
     *  RUNNING
     **********************************************/
    callbacks.add(initHome);
});

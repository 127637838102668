var WEB = WEB || {};
var callbacks = $.Callbacks();

$(document).ready(function() {
    'use strict';

    /***********************************************
     *  VARIABLES
     **********************************************/
    WEB.VARS = WEB.VARS || {
        WINDOW: $(window),
        BODY: $('body'),

        HEIGHT: window.innerHeight,
        WIDTH: window.innerWidth,
        IS_DESKTOP: false,
    };

    /***********************************************
     *  FUNCTIONS - visible only in this file
     **********************************************/
    function helperWindowSize() {
        WEB.VARS.HEIGHT = window.innerHeight;
        WEB.VARS.WIDTH = window.innerWidth;

        if(WEB.VARS.WIDTH > 1024) {
            WEB.VARS.IS_DESKTOP = true;
        }
    }

    function runMethods(object) {
        for (var method in object) {
            object[method]();
        }
    }

    /***********************************************
     *  INITIALIZAION - running all scripts
     **********************************************/
    function init() {
        runMethods(WEB.VIEWPORT);

        WEB.VARS.WINDOW.on('resize orientationchange', $.debounce(200, function() {
            runMethods(WEB.VIEWPORT);
        }));

        WEB.VARS.WINDOW.on('scroll',  $.throttle(200, function() {
            runMethods(WEB.SCROLL);
        }));
    }

    /***********************************************
     *  METHODS - visible in other JS files
     **********************************************/
    WEB.METHODS = {
        init: init
    };

    /***********************************************
     *  VIEWPORT - If viewport has been resized
     **********************************************/
    WEB.VIEWPORT = {
        helperWindowSize: helperWindowSize
    };

    /***********************************************
     *  SCROLL - If page is scrolled
     **********************************************/
    WEB.SCROLL = {};

    /***********************************************
     *  RUNNING
     **********************************************/
    callbacks.add(init);
});
